import * as React from "react"
import { CartHeader } from "@fidize/website.pages.cart.cartheader"
import { LineItem } from "@fidize/website.pages.cart.lineitem"
import { Extra } from "@fidize/website.pages.cart.extra"
import { Total } from "@fidize/website.pages.cart.total"
import { Aiuto } from "@fidize/website.pages.cart.aiuto"
import { Hpadding } from '@fidize/ui.hpadding';
import { Hr } from "@fidize/ui.hr"
import styles from '../styles/cart.module.scss';

const CartPage = () => {
  return (
        <>
          <head>
            <title>Fidize - Il tuo carrello</title>
          </head>
          <div >
              <CartHeader total={parseFloat(window.cart.totalPrice.amount).toFixed(2).replace('.', ',')} cart={window.cart} />
              <Hpadding padding="2">
                {
                  window.cart.lineItems.map((li) => {
                    return <LineItem item={li} />;
                  })
                }
              </Hpadding>

              {
                window.cart.lineItems.length > 0 ?
                <>
                  <Hpadding padding="2" style={{marginTop: "20px"}}>
                    <Extra />
                  </Hpadding>

                  <Hpadding padding="2" style={{marginTop: "20px"}}>
                    <Total cart={window.cart} />
                  </Hpadding>
                </>
                :
                <></>
              }


              <Aiuto />
          </div>
        </>
  )
}

export default CartPage
